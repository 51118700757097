import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
// import Capture from '../../assets/images/Capture.png'; 
import "./header.css";
import { FaBell } from "react-icons/fa";
import { RiSettings3Fill } from "react-icons/ri";
import { IoIosSearch } from "react-icons/io";
import { TbGridDots } from "react-icons/tb";
import navLogo from "../assets/navLogo.png"
import React, { useEffect } from 'react'

function HeaderComponent() {


   
    
   
    const Capture = "https://mdbcdn.b-cdn.net/img/Photos/Avatars/img(31).webp"
  return (
    <div> <>
    <Navbar expand="lg" className='plat-navbar' sticky="top">
        <Container fluid className='plat-container'>
            <Navbar.Brand href="#" className='plat-navbar-brand'> <img height= "40px" width="160px" src={navLogo}  alt="Capture" /></Navbar.Brand>
            <Navbar.Toggle aria-controls="navbarScroll" />
            <Navbar.Collapse id="navbarScroll" className='plat-navbar-collapse' >
            <Nav
                className="me-auto my-2 my-lg-0"
                style={{ maxHeight: '100px' }}
                navbarScroll
            >
                
               
            </Nav>
            <Form className="d-flex">
               
                    {/* <>
                        <Button variant="outline-success" color='link' className='active-btn ms-2' >Sign In</Button>
                        <Button variant="outline-success" color='link' className='active-btn ms-2' >Sign Up</Button> 
                    </> 
                    :  */}
                    <>
                    {/* <iconify-icon icon="mdi:dots-grid"  class='menu-icon-without-bg'></iconify-icon>
                    <TbGridDots className='menu-icon-without-bg'/> */}
                        <NavDropdown title={
                                <TbGridDots className='menu-icon-without-bg'/>
                        } id="navbarScrollingDropdown" className='navbar-top-menu plat-navbar-top-menu' >
                        </NavDropdown> 

                        <NavDropdown title={
                                <IoIosSearch className='menu-icon-with-bg'/>
                        } id="navbarScrollingDropdown" className='navbar-top-menu plat-navbar-top-menu' >
                        </NavDropdown> 

                        <Button variant="outline-success menu-admin-panel" >Admin Panel</Button>

                        <NavDropdown title={
                                <RiSettings3Fill className='menu-icon-with-bg'/>
                        } id="navbarScrollingDropdown" className='navbar-top-menu plat-navbar-top-menu' >
                        </NavDropdown> 

                        <NavDropdown title={
                                <FaBell className='menu-icon-with-bg'/>
                        } id="navbarScrollingDropdown" className='navbar-top-menu plat-navbar-top-menu' >
                        </NavDropdown> 

                        <NavDropdown title={
                                <img 
                                    src={Capture}
                                    className="menu-top-img"
                                    width="30"
                                    height="30"
                                    alt="Avatar"
                                />
                        } id="navbarScrollingDropdown" className='navbar-top-menu plat-navbar-top-menu' >
                           
                            <>
                                <label className='menu-topicon-username'>
                                 Karthikeyan
                                </label>
                                <NavDropdown.Divider />
                            </> 
                            
                           
                                <>
                                    <NavDropdown.Item className='navbar-top-submenu'>
                                    Dashboard
                                    </NavDropdown.Item>
                                    <NavDropdown.Item className='navbar-top-submenu' >
                                        Admin Panel
                                    </NavDropdown.Item>
                                </>
                             
                            <NavDropdown.Item className='navbar-top-submenu' >Logout</NavDropdown.Item>
                        </NavDropdown> 
                       
                    </> 
                
            </Form>
                
            </Navbar.Collapse>
        </Container>
    </Navbar>
</></div>
  )
}

export default HeaderComponent