import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import "../App.css";
import { useNavigate } from "react-router-dom";

export default function SignIn() {
    const navigate = useNavigate();
    const [emailId, setEmailId] = useState();
    const [password, setPassword] = useState();
    const [errFound, setErrFound] = useState(false);
    const [errDesc, setErrDesc] = useState("");
    const [embedUrl, setEmbedUrl] = useState();

    const handleSignInClick = () => {
        let data =
        {
            "event_type": "login",
            "meta_data": {
                "username": emailId,
                "password": password
            }
        }
        fetch(`https://9dz1ylkqu3.execute-api.us-east-1.amazonaws.com/Prod`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then((res) => { return res.json() })
            .then((res) => {
                if (res[0].status == "403") {
                    setErrFound(true);
                    setErrDesc(res[0].description);
                }
                if (res[0].Status == "200") {
                    localStorage.setItem("embedUrl",res[0].EmbedUrl)
                    navigate("/dashboard")
                    setEmbedUrl(res[0].EmbedUrl)
                }
            })
            .catch((err) => { console.log(err) })
    }

    return (
        <>
            <div className="container">
                <div className="d-lg-flex d-md-flex d-xs-block">
                    <div className="col-xl-3 col-lg-3 col-md-3 col-xs-12"> </div>
                    <div className="col-xl-6 col-lg-6 col-md-6 col-xs-12">
                        <div className="signInDiv border h-100 mt-5 pt-5">
                            <h3 className="text-center signInHeader"> CAMS SIGN IN</h3>
                            <div className="emailDiv m-3">
                                <label> Email ID </label>
                                <input type="email" className="form-control"
                                    onChange={e => setEmailId(e.target.value)} />
                            </div>
                            <div className="passwordDiv m-3">
                                <label> Password </label>
                                <input type="password" className="form-control"
                                    onChange={e => setPassword(e.target.value)} />
                                <div> {errFound ? <p className="text-danger m-1">  {errDesc} </p> : ""} </div>
                            </div>
                            <div className="p-3" style={{ "float": "right" }}>
                                <button className="signInButton" onClick={handleSignInClick}> Sign In </button>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-3 col-xs-0"> </div>
                </div>
            </div>
           
        </>
    )
}
