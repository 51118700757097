import logo from "./logo.svg";
import "./App.css";
import Header from "./components/headerComponent";
import HeaderNew from "./components/HeaderNew";
import HeaderComponent from "./components/headerComponent";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap";
import SignIn from "./components/signin";
import Dashboard from "./components/dashboard";
import { BrowserRouter as  Router ,Route, Routes } from "react-router-dom";


function App() {
  return (
    <>
     
        <HeaderComponent />
   
      <Router>
      <Routes>
        <Route path="/" element={<SignIn />} />
        <Route path="/dashboard" element={<Dashboard />} />
      </Routes>
      </Router>
     

    </>
  );
}

export default App;
