import React, { useEffect  , useState} from 'react'

function Dashboard({embedUrl}) {
    const [data , setData] = useState();

    useEffect(() => {
        setData(localStorage.getItem("embedUrl"))
    },[])
    
    const getProdData = () => {
        fetch(`https://9dz1ylkqu3.execute-api.us-east-1.amazonaws.com/Prod`)
        .then((res) => {return res.json()})
        .then((res) => { setData(res[0].EmbedUrl)})        
        .catch((err) => console.log(err))
    }

  return (
    <> 
    <iframe src={data} title="description" style={{"width" : "100%" , "height" : "100vh"}}></iframe>
    </>
  )
}

export default Dashboard